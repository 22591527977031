import React, { useState, useContext, useEffect } from "react";
import CountryActivity, { instanceOfCountryActivity } from "shared-models/build/country_activities";
import Country, {instanceOfCountry} from "shared-models/build/countries";
import { FirebaseAuthContext } from "../../context/firebase-auth";
import { sendPostRequest } from "../../util/util";

const CountryView = () => {
    const [countries, setCountries] = useState<Country[]>([]);
    const user = useContext(FirebaseAuthContext);
    const [selectedActivitiesMap, setSelectedActivitiesMap] = useState<{ [key: number]: { [key: number]: boolean } }>({});

    useEffect(() => {
        if (user !== null) {
            // Get the countries
            getCountryData();
        }
    }, [user]);

    const getCountryData = async () => {
        if (user === null) return;
        const [resp, err] = await sendPostRequest(user, "/country/get-countries", {}, "", "Failed to get the countries");
        if (err !== null) {
            console.log({ resp, err });
            return;
        }
        setCountries(resp.Countries);

        const initMap = resp.CountryActivities?.reduce((obj: { [key: number]: { [key: number]: boolean } }, ca: CountryActivity) => {
            if (!(instanceOfCountryActivity(ca))) return obj;
            
            if (ca.CountryId in obj) {
                const temp = obj[ca.CountryId];
                return {
                    ...obj, 
                    [ca.CountryId]: {
                        ...temp,
                        [ca.ActivityId]: true,
                    }
                }
            } else {
                return {
                    ...obj,
                    [ca.CountryId]: {
                        [ca.ActivityId]: true,
                    }
                }
            }
        }, {});
        setSelectedActivitiesMap(initMap);
    };

    return (
        <div>
            <h3 className="tw-text-lg tw-font-bold tw-my-4">
                Countries
            </h3>
            {
                countries.length > 0 ?
                <div className="tw-grid tw-grid-cols-4 md:tw-grid-cols-7 tw-gap-4 tw-items-center tw-mb-4 tw-font-bold">
                    <div>Id</div>
                    <div>Name</div>
                    <div>Description</div>
                    <div>Picture URL</div>
                    <div>Settings</div>
                    <div>Verified</div>
                    <div>Action</div>
                </div>
                :
                null
            }
            <div>
                {
                    countries.length > 0 ?
                    <div>
                   {
                        countries?.map((act) => {
                            if (!instanceOfCountry(act)) return null;
                            return (
                                <div className="tw-grid tw-grid-cols-4 md:tw-grid-cols-7 tw-gap-4 tw-items-center tw-py-4 tw-border-b-2">
                                    <div>{act.Id}</div>
                                    <div>{act.Name}</div>
                                    <div>{act.Description}</div>
                                    <div className="tw-break-words">{act.CountryPictureUrl}</div>
                                    <div className="tw-break-words">{JSON.stringify(act.Settings)}</div>
                                    <div>{act.Verified ? "yes" : "no" }</div>
                                    <div>
                                        <a href={`/admin-team/modify-country?data=${encodeURIComponent(JSON.stringify({ Country: act, CountryActivities: selectedActivitiesMap[act.Id] }))}`}>
                                            <button className="tw-white-button">
                                                Modify Country
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            )
                        })
                    }                
                    </div>
                    :
                    <div>
                        No countries in DB.
                    </div>
                }
            </div>
            <div className="tw-mt-4">
                <a href="/admin-team/modify-country">
                    <button className="tw-primary-button">
                        Add Country
                    </button>
                </a>
            </div>
        </div>
    )
};

export default CountryView;